// extracted by mini-css-extract-plugin
export var column = "ProviderBrochureNew__column__RrsKH";
export var container = "ProviderBrochureNew__container__iJQnH";
export var flex = "ProviderBrochureNew__flex__mjY5U";
export var flexColumn = "ProviderBrochureNew__flexColumn__UMqM6";
export var gap1 = "ProviderBrochureNew__gap1__shB9G";
export var gap2 = "ProviderBrochureNew__gap2__GM3kb";
export var gap3 = "ProviderBrochureNew__gap3__fY8LE";
export var gap4 = "ProviderBrochureNew__gap4__PbBOQ";
export var gap5 = "ProviderBrochureNew__gap5__m54tV";
export var pageContainer = "ProviderBrochureNew__pageContainer__Hb9DG";
export var pageTitle = "ProviderBrochureNew__pageTitle__CGxN9";
export var row = "ProviderBrochureNew__row__nFW6Y";