// extracted by mini-css-extract-plugin
export var buttonCreateContainer = "ProviderBrochureNewForm__buttonCreateContainer__WotMI";
export var column = "ProviderBrochureNewForm__column__swNf7";
export var documentRow = "ProviderBrochureNewForm__documentRow__ZJGXH";
export var editor = "ProviderBrochureNewForm__editor__jGeBD";
export var fileNameText = "ProviderBrochureNewForm__fileNameText__QGUDg";
export var fileSizeText = "ProviderBrochureNewForm__fileSizeText__aN3P2";
export var flex = "ProviderBrochureNewForm__flex__NnAOe";
export var flexColumn = "ProviderBrochureNewForm__flexColumn__Cdrgw";
export var flexContainer = "ProviderBrochureNewForm__flexContainer__p3n1h";
export var gap1 = "ProviderBrochureNewForm__gap1__TZq_w";
export var gap2 = "ProviderBrochureNewForm__gap2__Xt5LG";
export var gap3 = "ProviderBrochureNewForm__gap3__g_wAq";
export var gap4 = "ProviderBrochureNewForm__gap4__egwt1";
export var gap5 = "ProviderBrochureNewForm__gap5__JvbJ2";
export var grid = "ProviderBrochureNewForm__grid__IqkAw";
export var iconDelete = "ProviderBrochureNewForm__iconDelete__pd_t7";
export var iconView = "ProviderBrochureNewForm__iconView__j98vb";
export var labelContainer = "ProviderBrochureNewForm__labelContainer__lyAhT";
export var publishedAtPicker = "ProviderBrochureNewForm__publishedAtPicker__Y1sjQ";
export var row = "ProviderBrochureNewForm__row__sj2_U";
export var uploadDocumentButton = "ProviderBrochureNewForm__uploadDocumentButton__t5IwJ";